import React from "react";
import FadeInWhenVisible from "../FadeInWhenVisible";
import {
  Flex,
  Link,
  List,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const MenuOverlay = (props) => {
  const { onRequestClose } = props;
  const bgColor = useColorModeValue("background", "gray.700");
  const linkColor = useColorModeValue("blackAlpha.700", "whiteAlpha.600");
  const linkStyles = {
    fontFamily: "heading",
    fontSize: "6xl",
    _hover: {
      textDecor: "none",
      borderBottom: "5px solid",
      borderColor: linkColor,
    },
  };

  return (
    <Flex
      pos="absolute"
      alignItems="center"
      justifyContent="center"
      top={0}
      bottom={0}
      left={0}
      right={0}
      height="100vh"
      width="100vw"
      bgColor={bgColor}
    >
      <FadeInWhenVisible>
        <List p={0} textAlign="center">
          <ListItem my={8}>
            <Link as={NavLink} to="/" onClick={onRequestClose} {...linkStyles}>
              Work
            </Link>
          </ListItem>
          <ListItem my={8}>
            <Link
              as={NavLink}
              to="/about"
              onClick={onRequestClose}
              {...linkStyles}
            >
              About
            </Link>
          </ListItem>
          {/* <ListItem my={8}>
            <Link
              as={NavLink}
              to="/resume"
              onClick={onRequestClose}
              {...linkStyles}
            >
              Resume
            </Link>
          </ListItem> */}
        </List>
      </FadeInWhenVisible>
    </Flex>
  );
};

export default MenuOverlay;
