import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";

const Footer = () => {
  const bgColor = useColorModeValue("background", "background");
  return (
    <Flex
      as="footer"
      bgColor={bgColor}
      height={200}
      width="100%"
      alignItems="center"
      justifyContent="center"
      fontFamily="Fraunces"
      fontSize="sm"
      fontWeight={600}
      whiteSpace="nowrap"
    >
      Designed + coded in Austin, TX 🌾
    </Flex>
  );
};

export default Footer;
