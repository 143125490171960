import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const config = {
  useSystemColorMode: true,
};

const lightBg = "#F3F7F0";
const paper = "#f5f5ed";

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode(lightBg, "gray.800")(props),
      },
    }),
  },
  config,
  semanticTokens: {
    fonts: {
      body: "Noto Sans KR, sans-serif",
      heading: "Fraunces, Georgia, serif",
      mono: "Menlo, monospace",
    },
    colors: {
      background: {
        default: paper,
        _dark: "gray.600",
      },
      error: "red.500",
      success: "green.500",
      primary: {
        default: "red.500",
        _dark: "red.400",
      },
      secondary: {
        default: "red.800",
        _dark: "red.700",
      },
    },
  },
});

export default theme;
