import React from "react";
import Menu from "../../components/Menu";
import {
  Box,
  Flex,
  Link,
  List,
  ListItem,
  useBreakpoint,
  useColorModeValue,
} from "@chakra-ui/react";
import ToggleColorMode from "../../components/ToggleColorMode";
import { NavLink } from "react-router-dom";

const Header = (props) => {
  const { isMenuOpen, setIsMenuOpen } = props;
  const currentBreak = useBreakpoint();
  const showMenuToggle = currentBreak === "sm" || currentBreak === "base";
  const logoColor = useColorModeValue("blackAlpha.800", "whiteAlpha.800");
  const linkColor = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const hoverLinkColor = useColorModeValue("blackAlpha.900", "whiteAlpha.800");
  const linkStyleProps = {
    pb: 2,
    color: linkColor,
    _hover: {
      textDecoration: false,
      color: hoverLinkColor,
    },
    _activeLink: {
      borderBottom: `3px solid `,
      borderColor: linkColor,
      color: logoColor,
    },
  };
  return (
    <Box
      as="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mx={{ base: "5%", "2xl": "30%" }}
      py={6}
      className="header"
    >
      <Link
        as={NavLink}
        to="/"
        color={logoColor}
        fontFamily="heading"
        fontSize={["3xl", "4xl"]}
        fontWeight="bold"
        _hover={{ textDecoration: "none" }}
        mr={1}
      >
        d·tam
      </Link>
      <Flex>
        <Box mr={1}>
          <ToggleColorMode />
        </Box>
        {!showMenuToggle && (
          <List pt={1.5}>
            <ListItem display="inline" px={2}>
              <Link as={NavLink} to="/" {...linkStyleProps}>
                Work
              </Link>
            </ListItem>
            <ListItem display="inline" px={2}>
              <Link as={NavLink} to="/about" {...linkStyleProps}>
                About
              </Link>
            </ListItem>
            {/* <ListItem display="inline" px={2}>
              <Link as={NavLink} to="/resume" {...linkStyleProps}>
                Resume
              </Link>
            </ListItem> */}
          </List>
        )}
        {showMenuToggle && (
          <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        )}
      </Flex>
    </Box>
  );
};

export default Header;
