import React from "react";
import MenuOverlay from "../MenuOverlay";
import { Button, useColorModeValue } from "@chakra-ui/react";

const Menu = (props) => {
  const { isMenuOpen, setIsMenuOpen } = props;
  const hoverBgColor = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  return (
    <>
      {isMenuOpen && (
        <MenuOverlay onRequestClose={() => setIsMenuOpen(false)} />
      )}
      <Button
        variant="ghost"
        size="md"
        zIndex="1500"
        _hover={{
          bgColor: hoverBgColor,
        }}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        type="button"
      >
        {isMenuOpen ? "CLOSE" : "MENU"}
      </Button>
    </>
  );
};

export default Menu;
