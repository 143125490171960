import React from "react";
import Hero from "../Hero";
import { Box } from "@chakra-ui/react";

const Home = () => {
  return (
    <Box mx={{ base: "5%", lg: "15%", xl: "20%", "2xl": "30%" }} pt={8} pb={24}>
      <Hero />
    </Box>
  );
};

export default Home;
