import React from "react";
import { Box, Heading, Link, useColorModeValue } from "@chakra-ui/react";
import FadeInWhenVisible from "../../components/FadeInWhenVisible";

const Hero = () => {
  const h1LinkColor = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const h1HoverLinkColor = useColorModeValue("green.400", "green.100");
  return (
    <FadeInWhenVisible>
      <Box my={{ base: 2, xl: 16 }}>
        <Heading
          fontSize={{ base: "5xl", "2xl": "6xl" }}
          textAlign={{ xs: "center", sm: "center" }}
          lineHeight={1.5}
        >
          Hello - I'm Derek. <br />
          Currently a Software Engineer at{" "}
          <Link
            _hover={{
              textDecor: "none",
              borderBottom: "8px solid",
              borderColor: h1HoverLinkColor,
              color: h1HoverLinkColor,
            }}
            pb={1}
            color={h1LinkColor}
            href="https://hackerone.com"
            target="_blank"
            rel="noopener noreferer"
          >
            HackerOne
          </Link>
          .
        </Heading>
      </Box>
    </FadeInWhenVisible>
  );
};

export default Hero;
