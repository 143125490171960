import React from "react";
import { Button, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

const ToggleColorMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const hoverBgColor = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  return (
    <Button
      variant="ghost"
      onClick={() => toggleColorMode()}
      size="md"
      zIndex={1500}
      _hover={{ bgColor: hoverBgColor }}
    >
      {colorMode === "dark" ? <SunIcon color="yellow.400" /> : <MoonIcon />}
    </Button>
  );
};

export default ToggleColorMode;
