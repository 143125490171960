import React, { useState, useEffect } from "react";
import FadeInWhenVisible from "../../components/FadeInWhenVisible";
import { motion, useAnimation } from "framer-motion";
import {
  Flex,
  Box,
  Heading,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const About = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const controls = useAnimation();
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    if (imageLoaded) {
      controls.start({ opacity: 1, translateY: -25 });
    }
  }, [controls, imageLoaded]);

  const emailTextColor = useColorModeValue("blackAlpha.800", "whiteAlpha.700");
  const linkColor = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const h1HoverLinkColor = useColorModeValue("green.500", "pink.300");
  const stravaLinkColor = useColorModeValue("orange.400", "orange.300");
  return (
    <Box
      mt={{ sm: 10, md: 10, xl: 16 }}
      mx={{ sm: "5%", md: "20%", xl: "20%", "2xl": "30%" }}
    >
      <Flex
        flexDir={{ base: "column", xl: "row" }}
        alignItems={{ base: "center", xl: "flex-start" }}
        justifyContent={{ base: "center" }}
        columnGap={6}
        rowGap={2}
        my={5}
      >
        <Box width={[250, 300, 400]}>
          <motion.img
            id="me"
            src="resources/me.jpg"
            initial={{
              opacity: 0,
              translateY: 0,
            }}
            animate={controls}
            transition={{
              duration: 0.3,
            }}
            onLoad={handleImageLoaded}
          />
        </Box>
        <FadeInWhenVisible>
          <Box
            mt={{ base: 10, xl: 0, "2xl": 0 }}
            mb={6}
            mx={6}
            fontFamily="Fraunces"
            fontSize={{ base: "md", xl: "xl" }}
            maxWidth={{ xl: 560 }}
          >
            <Text>
              I'm Derek Tam, a web developer based in Austin, TX. I'm currently
              working at{" "}
              <Link
                href="https://hackerone.com"
                target="_blank"
                rel="noopener noreferrer"
                borderColor={linkColor}
                color={linkColor}
                _hover={{
                  color: h1HoverLinkColor,
                  textDecor: "none",
                  borderBottom: "2px solid",
                  borderColor: h1HoverLinkColor,
                }}
              >
                HackerOne
              </Link>
              .
            </Text>
            <br />
            <Text>
              I'm always striving to learn and improve on my craft and enjoy
              working with others who share that mindset.
            </Text>
            <br />
            <Text>
              When I'm not working, you can find me{" "}
              <Link
                href="https://www.strava.com/athletes/46226029"
                target="_blank"
                rel="noopener noreferrer"
                borderColor={linkColor}
                color={linkColor}
                _hover={{
                  color: stravaLinkColor,
                  textDecor: "none",
                  borderBottom: "2px solid",
                  borderColor: stravaLinkColor,
                }}
              >
                running on Town Lake{" "}
              </Link>
              , spending time with friends/family, or watching re-runs of
              Survivor.
            </Text>
          </Box>
        </FadeInWhenVisible>
      </Flex>
      <FadeInWhenVisible>
        <Box mt={10} mx={{ base: 6, xl: 0 }} mb={20}>
          <Heading fontSize="2xl" mb={4}>
            Contact
          </Heading>
          <Text color={emailTextColor} fontSize="xs" fontWeight={600} mb={1}>
            EMAIL
          </Text>
          <Text fontFamily="mono">derekhtam@gmail.com</Text>
        </Box>
      </FadeInWhenVisible>
    </Box>
  );
};

export default About;
