import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "../Home";
import About from "../About";
// import Resume from "../Resume";
import Header from "../Header";
import Footer from "../Footer";
import {
  Box,
  ChakraProvider,
  ColorModeScript,
  IconButton,
} from "@chakra-ui/react";
import themes from "../../themes";
import { ArrowUpIcon } from "@chakra-ui/icons";

function scrollToTop() {
  // Scroll to top logic
  document.documentElement.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState();

  const [showScrollToTopButton, setShowScrollToTopButton] = React.useState(
    document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
  );

  document.addEventListener("scroll", () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShowScrollToTopButton(true);
    } else {
      setShowScrollToTopButton(false);
    }
  });

  const theme = themes.dtam;

  return (
    <>
      <ColorModeScript initialColorMode={theme.config.useSystemColorMode} />
      <ChakraProvider theme={theme}>
        <Box className="App">
          <BrowserRouter>
            <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            <Box flex={1}>
              {!isMenuOpen && (
                <Routes>
                  <Route path="/" exact element={<Home />} />
                  <Route path="*" element={<Navigate to="/" />} />
                  <Route path="/about" element={<About />} />
                  {/* <Route path="/resume" element={<Resume />} /> */}
                </Routes>
              )}
            </Box>
            {showScrollToTopButton && (
              <IconButton
                variant="solid"
                size="lg"
                bgColor="yellow.500"
                _hover={{
                  bgColor: "yellow.400",
                }}
                onClick={scrollToTop}
                pos="fixed"
                bottom="60px"
                right={{ base: "5%" }}
                zIndex={1200}
              >
                <ArrowUpIcon />
              </IconButton>
            )}
            <Footer />
          </BrowserRouter>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default App;
